<template>
  <div>
    <div class="appbar-list__global-dropdown__item" @click="openModal">
      <div class="title">Showing artists in</div>
      <div class="value value--country">{{ (selected || {}).name }}</div>
    </div>

    <div class="location-search">
      <modal name="country-modal" height="auto" :adaptive="true"
        @opened="openMultiselect"
      >
        <multiselect
          ref="multiselect"
          :value="selected"
          @select="onSelected"
          placeholder="Search country"
          :options="countries"
          label="name"
          :internalSearch="true"
          track-by="id"
        >
          <span slot="noResult">Nothing was found</span>
        </multiselect>
        <div></div>
      </modal>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    selectedCountry: Object
  },
  components: {
    Multiselect
  },
  data: function () {
    return {
      selected: this.selectedCountry,
      countries: []
    }
  },
  mounted() {
    let option = this.selectedCountry
    if (option.id != localStorage.getItem('country_id')) {
      this.$axios({url: `/global_settings/select_country?id=${option.id}`, baseURL: '', method: 'post'})
      .then(response => {
        this.$modal.hide('country-modal')
        localStorage.setItem('location_country', option.slug)
        localStorage.setItem('country_id', option.id)
        localStorage.setItem('country_name', option.name)
      })
    }
  },
  methods: {
    onSelected (option) {
      this.selected = option
      this.$axios({url: `/global_settings/select_country?id=${option.id}`, baseURL: '', method: 'post'})
        .then(response => {
          this.$modal.hide('country-modal')
          localStorage.setItem('location_country', option.slug)
          localStorage.setItem('country_id', option.id)
          localStorage.setItem('country_name', option.name)
          localStorage.setItem('show_city', false)
          window.history.replaceState(null, null, this.generateShortForm(option.name).toLowerCase());
          window.dispatchEvent( new Event('storage') )
          if (!window.location.href.includes("discover")) {
            location.reload()
          }
        })
    },
    generateShortForm(name) {
      const commonWords = ["of", "the", "and", "in", "on"];
      name = name.replace(/\s*\(.*?\)\s*/g, '');
      const words = name.split(' ');
      if (words.length > 1) {
        return name.split(' ').filter(word => !commonWords.includes(word.toLowerCase())).map(word => word[0].toUpperCase()).join('')
      }
      return name
    },
    openModal () {
      if (this.countries.length > 0) {
        this.$modal.show('country-modal')
      } else {
        this.$axios({url: '/global_settings/countries', baseURL: '', method: 'get'})
          .then(response => {
            this.countries = response.data
            this.$modal.show('country-modal')
          })
      }
    },
    openMultiselect () {
      this.$refs.multiselect.activate();
    },
    closeMultiselect () {
      this.$refs.multiselect.deactivate();
    }
  }
}
</script>
